import React from 'react'
import HeroBanner from "../components/Common Banner Component/HeroBanner";
import ImgByContent from '../components/Image By Content/ImgByContent';
import WorkIndustry from '../components/WorkIndustry/WorkIndustry';
import TextContent from '../components/TextContent/TextContent';
import Testimonials from '../components/Testimonials/Testimonials';

// 

function OurClient() {
  return (
    <div>
        <HeroBanner
            Paragraph={``}
            Heading={`OUR `}
            HighlightedSpan={"CLIENTS"}
            whiteHiglightedText="International Clients Across Many Business Sectors"
            isBtn={false}
            bgImg={'/images/creatives/client_banner.webp'}
            isChevron={false}
        />

      <ImgByContent
        Heading={"Digital Connections & "}
        HighlightedHeading={"Creative Displays"}
        subHeading={""}
        Paragraph={`At Blue Fysh, we make digital connections and creative displays work for our clients. We're in the business of partnering with companies across all sectors to wire them into the digital age. Our expertise lies in crafting and implementing digital displays that ensure constant connectivity, adaptability, and visibility.`}
        btnText={""}
        imgLink="/images/creatives/client-text-0.1.webp"
      />


      <div className='container text-center my-5 pb-3'>
        <h3 className='body-heading px-5'>Examples of our work with amazing clients that <span className='color-blue'>we are proud to share</span> </h3>

      </div>

      <ImgByContent
        Heading={"Fast Food & "}
        HighlightedHeading={"Hospitality"}
        subHeading={""}
        // Paragraph={`Streamlined for Speed and Service. Our tailored digital solutions cater to the fast-moving nature of the fast food and hospitality industries: \nDrive-through innovation: Digital Drive-Thru Menu Boards, integrated, headset-connected digital menu boards for efficient service and an enhanced customer experience.\nIn-store interaction: Dynamic digital menu boards at the point of order, bolstered by sophisticated security systems and remote surveillance, all interconnected and equipped with smart sensors and timers for optimum performance.`}
        Paragraph={`Our digital solutions are optimized for the high-speed world of fast food and hospitality. From drive-thru and headset-connected menu boards to dynamic digital displays at the point of order, we deliver seamless, remotely managed systems with built-in security and network monitoring.\n Come behind the scenes with us for a case study on one of Canada’s biggest burger chains, Harvey’s, and see how we brought their digital menus to life.`}
        btnText={"Let's Connect"}
        href='tel'
        shiftRow={true}
        imgLink="/images/creatives/client-text-1.webp"
        secondBtn={"/case-study/fast-food-and-hospitality"}
      />

      <ImgByContent
        Heading={"Comprehensive Structured Cabling and "}
        HighlightedHeading={"Network Installations"}
        subHeading={""}
        // Paragraph={`Blue Fysh specializes in top-tier Structured Cabling & Network Installations. Notably, we've successfully implemented robust networks for Mr. Lube and Tim Hortons locations nationwide. Choose us for dependable and scalable network solutions that propel your business forward with seamless connectivity.`}
        Paragraph={`Expertly built for reliability and scale. We specialize in top-tier structured cabling and network installations so your network never drops, delivering robust, dependable solutions. Notably, we’ve successfully implemented networks for MR. Lube and Tim Hortons locations nationwide, ensuring seamless device connectivity to keep businesses running smoothly.\n Join us behind the scenes for a case study on a network cabling at one of our Tim Hortons locations.`}
        btnText={"Let's Connect"}
        href='tel'
        shiftRow={false}
        imgLink="/images/creatives/client-text-0.2.webp"
        secondBtn={"/case-study/comprehensive-structured-cabling"}
      />
      {/* <ImgByContent
        Heading={"Security and "}
        HighlightedHeading={"Remote Monitoring Systems"}
        subHeading={""}
        Paragraph={`need copy`}
        btnText={"Let's Connect"}
        href='tel'
        shiftRow={true}
        imgLink="/images/creatives/home-text-1.webp"
      /> */}
      <ImgByContent
        Heading={"Specialized"}
        HighlightedHeading={" Sectors"}
        subHeading={""}
        // Paragraph={`Engaging Digital Environments. We empower commercial spaces, automotive dealerships, call centers, and government agencies with digital displays and video monitors designed to engage and inform.\nInformation at a glance: Strategic placement of information boards for optimal visitor and customer communication.\nVisual impact: Striking video walls that captivate and inform.\nBrand presence: LED ribbons that enhance brand visibility and customer engagement.`}
        Paragraph={`We equip commercial spaces, automotive dealerships, call centers, and government agencies with engaging digital environments that captivate and engage customers and increase brand visibility. With strategic placement of menu boards and screens, our striking video displays ensure an immersive and informative experience.\n Explore our case study on a Nespresso site installation to see our process in action.`}
        btnText={"Let's Connect"}
        href='tel'
        shiftRow={true}
        // imgLink="/images/creatives/client-text-0.3.webp"
        imgLink="/images/creatives/client-text-0.3-new.png"
        secondBtn={"/case-study/commercial-specialized-sectors"}
      />
      <ImgByContent
        Heading={"Education, Finance & "}
        HighlightedHeading={"Healthcare"}
        subHeading={""}
        // Paragraph={`Visual Solutions That Inform and Guide \n Universities, financial services, and healthcare providers can transform their spaces with our digital solutions, from informative videos to intuitive wayfinding systems.\nNavigational clarity: Wayfinding screens that guide and inform with ease.\nExpert installation: Professional digital screen installations tailored to your environment.\nInfrastructure you can trust: Robust structured cabling for unwavering reliability.`}
        Paragraph={`Universities, financial services, and healthcare providers transform their spaces with our digital solutions, from informative videos to intuitive wayfinding systems. Our professional installations are tailored to your environment, ensuring seamless integration and enhanced user experience.\n Go behind the scenes of the world's largest transparent LED wall installation to see how we bring multiple providers together for a cutting-edge digital experience.`}
        btnText={"Let's Connect"}
        href='tel'
        shiftRow={false}
        imgLink="/images/creatives/client-text-2.3-new.png"
        secondBtn={"/case-study/education-finance-health-care"}
      />
      <ImgByContent
        Heading={"COMMERCIAL & "}
        HighlightedHeading={"LARGE SCALE"}
        subHeading={""}
        // Paragraph={`Blue Fysh delivers focus and clarity to large-scale venues, offering modern solutions that ensure messaging is both seen and engaged with through cutting-edge technology and networking. By leveraging modular technology, we design versatile, scalable screen systems that seamlessly adapt to the unique needs of any space, complementing and aligning with the venue’s Structure.\n No space is too vast, and no venue too challenging. Explore our project at the Metro Toronto Convention Centre, Canada’s largest convention center with over 600,000 square feet for an inside look at how we use modular screens to maximize engagement and transform expansive spaces.`}
        Paragraph={`At BlueFysh we deliver modern solutions that ensure messaging is both seen and engaged through cutting-edge technology and networking. By leveraging modular technology, we design versatile, scalable screen systems that seamlessly adapt to the unique needs of any space, complementing and aligning with the venue’s structure. No space is too vast, and no venue too challenging.\n Explore our project at the Metro Toronto Convention Centre, Canada’s largest convention center with over 600,000 square feet for an inside look at how we use modular screens to maximize engagement.`}
        btnText={"Let's Connect"}
        href='tel'
        shiftRow={true}
        imgLink="/images/creatives/client-text-2.2.png"
        secondBtn={"/case-study/commercial-and-large-scale"}
      />
      <ImgByContent
        Heading={"GROCERY"}
        subHeading={""}
        // Paragraph={`At Blue Fysh, we deliver advanced navigational solutions to modernize your space. Using SIRL, we turn data into actionable insights, tracking customer movement and identifying high-traffic areas to optimize layouts.\n Our dynamic screens deliver targeted, engaging content at the right time, enhancing customer experiences. From aisle displays to menu boards, our high-clarity screens integrate with navigational data to boost engagement, improve flow, and maximize sales—helping you create a smarter, more impactful space.`}
        Paragraph={`Using SIRL, we transform data into actionable insights, tracking customer movement, identifying high-traffic areas, and optimizing layouts and messaging. Paired with our dynamic screens, we deliver targeted, engaging content at the right time, enhancing the customer experience from in-aisle displays to menu boards.\n Join us behind the scenes at a local mom-and-pop grocer to see how we brought their space into the modern age.`}
        btnText={"Let's Connect"}
        href='tel'
        shiftRow={false}
        imgLink="/images/creatives/client-text-2.1.png"
        secondBtn={"/case-study/grocery"}
      />
      {/* <ImgByContent
        Heading={"Our Track Record "}
        HighlightedHeading={"Speaks Volumes"}
        subHeading={""}
        Paragraph={`With a vast portfolio of installations across diverse business channels, Blue Fysh stands as a beacon of innovation in digital display solutions. Take a look at comments from our  satisfied clients and see how we can bring your digital aspirations to fruition.`}
        btnText={"Let's Connect"}
        href='tel'
        shiftRow={true}
        imgLink="/images/creatives/home-text-1.webp"
      /> */}
      {/* <div style={{height:"50px"}}></div> */}
      
      <WorkIndustry/>

      <Testimonials/>
    </div>
  )
}

export default OurClient