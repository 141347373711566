import React from 'react'
import HeroBanner from "../../components/Common Banner Component/HeroBanner";

const content = {
    heroHeading:"GROCERY",
    bgImg:"/images/creatives/grocery-hero.png",
    mainContent:[
        {
            heading: "Client Overview",
            para: "Maywood Markets is a unique gourmet food market in New Jersey, proudly family-owned and known for its knowledgeable staff and friendly, personalized service. They offer a variety of services, including online ordering and catering, along with a bakery, deli, seafood, butcher, and more.",
            img:true,
            img1:"/images/caseStudy/grocery/1.png",
            img2:"/images/caseStudy/grocery/2.png",
            img3:"/images/caseStudy/grocery/3.png",
            img4:"/images/caseStudy/grocery/4.png",
        },
        {
            heading: "Problem",
            para: "Maywood Markets aimed to enhance their marketing efforts by targeting clients with more personalized promotions. They focused on seasonal trends, market dynamics, quicker discount rollouts, and tailored ads for specific store areas. Their building had a legacy feel, featuring old-style hanging signs and a handmade appearance that evoked decades past. This workflow demanded significant man-hours for management and updates, resulting in a slower overall turnaround and a reluctance to adopt new trends."
        },
        {
            heading: "Solution",
            para: "Blue Fysh transformed Maywood Markets by installing cutting-edge displays and premium audio speakers from top brands like LG and Bose. The upgraded system enhances sales and promotions with immersive sound and strategically placed visuals. Additionally, we integrated SIRL and other advanced technologies to analyze customer behavior, identifying high-traffic areas and engagement zones. This data-driven approach optimizes screen placement and enables targeted advertising by section and season."
        },
        {
            heading: "Implementation",
            para: "Blue Fysh installed state-of-the-art equipment, integrating it seamlessly into the building's existing structure with minimal renovations. This approach preserved the classic look and feel of the space while strategically placing audio, screens, and other tech throughout the store. The installations complemented the store's flow, enhancing the overall experience without disruption through thoughtful and optimized hardware placement."
        },
        {
            heading: "Resolution",
            para: "Clients will now receive timely information on sales and promotions, allowing Maywood to communicate more directly and efficiently. This ensures no sales opportunities are missed and that customers stay informed. The digital tools complement the warm, friendly service provided by Maywood's staff, enhancing rather than replacing the human touch. This creates a perfect synergy of modern efficiency and classic service, making Maywood an unmatched shopping experience.",
            imgNew:true,
            img1:"/images/caseStudy/grocery/5.png",
            img2:"/images/caseStudy/grocery/6.png",
        },
        {
            heading: "Conclusion",
            para: "Maywood is now well-positioned for the future, ready to embrace modern trends and improve communication with shoppers. By utilizing advanced audio, video and geo technology, they create an experience that keeps customers informed and aids in decision-making. This enhanced shopping experience not only future-proofs the market but also offers clients a sleek, efficient environment where they feel informed and empowered to make choices.",
            vid:"https://blue-fysh-cdn-c2f316e15a8b.herokuapp.com/uploads/caseStudy/grocery/vid-1.mp4"
        }
    ]         
}

function Grocery() {
  return (
          <>
            <div >
              
              <HeroBanner
                Paragraph={``}
                Heading={content.heroHeading}
                HighlightedSpan={""}
                // blueHiglightedText={content.category1}
                isBtn={false}
                bgImg={content.bgImg}
                isChevron={false}
            />
              <div className='main-content standard-padding-space'>
                <div className='container'>
                    {
                        content.mainContent.map((ele)=>{
                            return(
                                <div className='pb-4'>
                                <h2 className="body-heading ">{ele.heading}</h2>
                                <p className="body-paragraph">{ele.para}</p>
                                {
                                    ele.img &&
                                    <div className='w-100 row pt-3 pb-5 gy-4'>
                                        <div className='col-lg-6'>
                                            <img src={ele.img1} alt='thumbnail' className='img-fluid '/>
                                        </div>
                                        <div className='col-lg-6'>
                                            <img src={ele.img2} alt='thumbnail' className='img-fluid '/>
                                        </div>
                                        <div className='col-lg-6'>
                                            <img src={ele.img3} alt='thumbnail' className='img-fluid '/>
                                        </div>
                                        <div className='col-lg-6'>
                                            <img src={ele.img4} alt='thumbnail' className='img-fluid '/>
                                        </div>
                                    </div>
                                }
                                {
                                    ele.imgNew &&
                                    <div className='w-100 row pt-3 pb-5'>
                                        <div className='col-lg-6'>
                                            <img src={ele.img1} alt='thumbnail' className='img-fluid '/>
                                        </div>
                                        <div className='col-lg-6'>
                                            <img src={ele.img2} alt='thumbnail' className='img-fluid '/>
                                        </div>
                                    </div>
                                }
                                {
                                    ele.vid &&
                                    <div className='w-100 text-center'>
                                         <video autoPlay loop muted controls playsInline controlslist="nodownload" className='w-75'>
                                            <source src={ele.vid} type="video/mp4" />
                                        </video>
                                    </div>
                                }
                                </div>
                            )
                        })
                    }

                </div>
              </div>
            </div>
          </>
  )
}

export default Grocery