import React from "react";
import styles from "./Hero.module.css";
import videoBG from "./video.mp4";
import { Swiper, SwiperSlide } from "swiper/react";
import Header from "../Header/Header";
import { Autoplay } from "swiper/modules";

const partnerImages = [
  // "/images/icons/partner-logo-1.webp",
  "/images/icons/partner-logo-2.webp",
  "/images/icons/partner-logo-3.webp",
  "/images/icons/partner-logo-4.webp",
  "/images/icons/partner-logo-5.webp",
  "/images/icons/partner-logo-6.webp",
  "/images/icons/partner-logo-7.webp",
  "/images/icons/partner-logo-8.webp",
  "/images/icons/partner-logo-9.webp",
  "/images/icons/partner-logo-10.webp",
  "/images/icons/partner-logo-11.webp",
  "/images/icons/partner-logo-12.webp",
  "/images/icons/partner-logo-13.webp",
  "/images/icons/partner-logo-14.webp",
  "/images/icons/partner-logo-15.webp",
  "/images/icons/partner-logo-16.webp",
  "/images/icons/partner-logo-17.webp",
]

const Hero = () => {
  return (
    <>
        <div style={{position:"relative",zIndex:"10"}}>

        <Header/> 
        </div>
      <div className={styles.Hero}>
        <div className={styles.overlay}></div>
        <video
          className={styles.video}
          src={videoBG}
          autoPlay={true}
          loop={true}
          muted={true}
          playsInline={true}
        />
        <div className={styles.Hero_Content}>
          <h3 className="hero-section-heading">
            <span>
            Delivering Integrated
              <br />
            </span>{" "}
            <span className="color-blue">
            Technology Solutions
            </span>
          </h3>
          <p className="body-paragraph text-center" style={{color:"white"}}>
          Anywhere in the USA and Canada we provide industry leading solutions for your digital and network needs.
          </p>
        </div>
        <div className={styles.logosCont}>
          <Swiper
            loop={true}
            autoplay={{
              delay: 1800,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            breakpoints={{
              1200: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 25,
              },
              778: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              480: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
            }}
          >
            {
              partnerImages.map((ele)=>{
                return(
                  <SwiperSlide className={styles.swiperSlide}>
                    <img className="img-fluid" src={ele} alt="partner company logo" />
                  </SwiperSlide>
                )
              })
            }
           
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Hero;
