import React, { Fragment, useEffect, useState } from 'react'
import HeroBanner from '../components/Common Banner Component/HeroBanner'
import MediaList from '../components/MediaList/MediaList'

function Media() {

  const [videoLink,setVideoLink] = useState(null)

  return (
    <Fragment>
        <HeroBanner
            Paragraph={``}
            Heading={`MEDIA`}
            HighlightedSpan={""}
            isBtn={false}
            isChevron={false}
            bgImg={'/images/creatives/media-hero-bg.jpg'}
        />

        <MediaList
          updateVideoLink={setVideoLink}
        />

        <VideoModalContainer
        showVideo={videoLink}
        />

    </Fragment>
  )
}

export default Media

const VideoModal = ({ isOpen, onClose, videoSrc }) => {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (
      e.target.classList.contains("modal-overlay") ||
      e.target.closest(".modal-content") === null
    ) {
      onClose();
    }
  };
  
  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        <video controls autoPlay className="modal-video" playsInline controlslist="nodownload">
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

const VideoModalContainer = (showVideo) => {
  const [isModalOpen, setIsModalOpen] = useState(showVideo?.showVideo);

  useEffect(()=>{
    setIsModalOpen(showVideo?.showVideo)
  },[showVideo])

  return (
    <div>
      <VideoModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        videoSrc={isModalOpen}
      />
    </div>
  );
};




