import React,{useState} from "react";
import { useNavigate } from "react-router-dom"
import "./Footer.css"
import axios from "axios"
import { Spinner } from "react-bootstrap";

function Footer() {
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const formSubmitHandler = (e) => {
        e.preventDefault();
        setLoading(true);
        axios
          .post("/send-newsletter", {
            email: email,
            formType: "Newsletter",
          })
          .then((response) => {
            if (response.data.msg === "success") {
              setError(false);
              setSuccess("Newsletter Subscribed Successfully!");
              setLoading(false);
              setEmail("");
            } else if (response.data.msg === "fail") {
              setSuccess(false);
              setError("Email failed to send, try again by reloading the page.");
              setLoading(false);
              setEmail("");
            }
          });
      };
  return (
    <div className="footer-container">
        <div className="container">
        {/* newsletter Upper section */}
        <div className="upper-newsletter-container ">
            <div className="text-container">
                <div className="newsletter-logo">
                    <img  src="/images/icons/newslater.png" alt="mail icon" />
                </div>
                <div className="text-content">
                    <h3>Subscribe Our Newsletter</h3>
                    <p>Get the latest insights from the industry experts.</p>
                </div>
            </div>
            <form className="newsletter-form" onSubmit={formSubmitHandler}>
                
                <input 
                         required
                         type="email"
                         value={email}
                         maxLength="56"
                         onChange={(e) => setEmail(e.target.value)}
                        placeholder='Enter your email'
                    />
                <button><span className="subscribe-btn-text">Subscribe</span> <img src="/images/icons/Icon-feather-bell.svg" alt="bell icon" />
                {loading ? (
                            <Spinner
                                animation="border"
                                role="status"
                                style={{ color: "white" ,width:"15px",height:"15px",marginLeft:"10px"}}
                            />
                        ) : (
                        success ? (
                            <div >
                            {setLoading(false)}
                            {alert(success)}
                            {setSuccess(false)}
                            </div>
                        ) : (
                            error && (
                            <div>
                                {setLoading(false)}
                                {alert(error)}
                                {setError(false)}
                            </div>
                            )
                        )
                        )}
                </button>
            </form>
        </div>

        {/* div for border lining */}
        <div className="line-seprator"></div>

        {/* middle section */}

        <div className="middle-links-container row">
            <div className="lef col-xl-3 col-lg-6 text-lg-start text-center mb-lg-0 mb-3">
                <img src="/images/icons/logo.png" alt="brand logo" style={{cursor:"pointer",maxWidth:"220px"}} onClick={()=>{navigate("/home")}}/>
                <p className="body-paragraph mt-2">We implement digital networking solutions that connect people to brands in the places they live, love and work.</p>
            </div>
            <div className="righ col-xl-3 col-lg-6">
                <div className="px-xl-2 px-0">
                <ul className="right-upper gap-md-4 gap-3 d-flex flex-column align-items-lg-start align-items-center justify-content-center">
                    <h3 className="body-heading" style={{fontSize:"25px", color:"white"}}>Quick Links</h3>
                    <li onClick={()=>{navigate("/about")}}>About</li>
                    <li onClick={()=>{navigate("/services")}}>Services</li>
                    <li onClick={()=>{navigate("/blog")}}>Blogs</li>
                    <li onClick={()=>{navigate("/contact")}}>Contact</li>
                </ul>
                </div>

            </div>
            <div className="col-xl-6 d-flex flex-row  row">
                    <ul className="right-bottom col-xl-6 col-md-12 d-flex flex-column  align-items-xl-start align-items-center  justify-content-start px-md-0 px-2 py-lg-0 py-3">
                    <h3 className="body-heading" style={{fontSize:"25px", color:"white"}}>Canada <img src="/images/icons/canada-flag.png" alt="flag" style={{width:"30px",height:"30px"}}/></h3>
                        <li>
                            <img src="/images/icons/phone.png" alt="phone icon" />
                            <a href="tel:905-713-0564">905-713-0564</a>
                        </li>
                        <li>
                            <img src="/images/icons/mail.png" alt="mail icon" />
                            <a href="mailto:sales@bluefysh.com">sales@bluefysh.com</a>
                        </li>
                        <li >
                            <img src="/images/icons/location.png" alt="location icon" />
                            <a href="#">2495 Lloydtown Road, <br className="d-xl-block d-none" />King City ON L7B 1A3</a>
                        </li>
                    </ul>
                    <ul className="right-bottom col-xl-6 col-md-12 d-flex flex-column  align-items-xl-start align-items-center  justify-content-start px-md-0 px-2 py-lg-0 py-3">
                    <h3 className="body-heading" style={{fontSize:"25px", color:"white"}}>USA <img src="/images/icons/usa-flag.png" alt="flag" style={{width:"30px",height:"30px"}}/></h3>
                        <li>
                            <img src="/images/icons/phone.png" alt="phone icon" />
                            <a href="tel:925-832-0920">925-832-0920</a>
                        </li>
                        <li>
                            <img src="/images/icons/mail.png" alt="mail icon" />
                            <a href="mailto:info@bluefysh.com">info@bluefysh.com</a>
                        </li>
                        <li>
                            <img src="/images/icons/location.png" alt="location icon" />
                            <a href="#">12210 SW Main St.,<br className="d-xl-block d-none" /> Portland, OR 97223</a>
                        </li>
                    </ul>
                </div>
        </div>

        </div>
        {/* Lower sections */}

        <div className="lower-container">
           <div className="container d-flex flex-column flex-md-row text-center align-items-center justify-content-between">
            <small>Made With ❤️ By <a href="https://www.creativesquad.ca/" target="blank">Creative Squad</a></small>
            <small>© Copyright 2024 Blue Fysh Inc - All Rights Reserved</small>     
            <ul className="lower-link-list justify-content-md-start justify-content-center">
                    <a href="https://www.linkedin.com/company/103431298/" target="blank"><li><img src="/images/icons/linked-footer.svg" alt="linked in icon" /></li></a>
                    <a href="https://www.facebook.com/profile.php?id=61560526252543" target="blank"><li><img src="/images/icons/fb-footer.svg" alt="facebook icon" /></li></a>
                    <a href="https://x.com/home?lang=en" target="blank"><li><img src="/images/icons/twitter-footer.svg" alt="twitter icon" /></li></a>
                    <a href="https://www.instagram.com/bluefyshofficial/" target="blank"><li><img src="/images/icons/insta-footer.svg" alt="instagram icon" /></li></a>
                </ul>
           </div>
        </div>
    </div>
  )
}

export default Footer