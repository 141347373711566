import React from 'react'
import "./MediaList.css"
import { useRef } from 'react'

const MediaListAr = [
    {
        videoLink:"https://blue-fysh-cdn-c2f316e15a8b.herokuapp.com/uploads/caseStudy/cabling/vid-1.mp4",
        heading:"Tim Hortons",
        thumbnail:"/images/creatives/thumbnail-1.png",
        content:"Get an exclusive behind-the-scenes look at how we upgraded QSR spaces with cutting-edge networking solutions."
        
    },
    {
        videoLink:"https://blue-fysh-cdn-c2f316e15a8b.herokuapp.com/uploads/caseStudy/HM/vid-1.mp4",
        heading:"H&M",
        thumbnail:"/images/creatives/thumbnail-2.png",
        content:"The Digital Out-of-Home advertising industry is set to surge in the coming years and for good reason. With 36% of viewers taking action after engaging with these displays, the impact is undeniable. Take an exclusive look at our H&M site, where we delivered both indoor and outdoor installations."
    },
    {
        videoLink:"https://blue-fysh-cdn-c2f316e15a8b.herokuapp.com/uploads/caseStudy/food/vid-1.mp4",
        heading:"Harveys",
        thumbnail:"/images/creatives/thumbnail-3.png",
        content:"80% of customers find digital menus easier to read and more visually appealing than static boards. Get a behind-the-scenes look at one of our Harvey's burger installations."
    },
    {
        videoLink:"https://blue-fysh-cdn-c2f316e15a8b.herokuapp.com/uploads/caseStudy/education/vid-1.mp4",
        heading:"BMO",
        thumbnail:"/images/creatives/thumbnail-4.png",
        content:"Having installed the world’s largest transparent LED wall designed to be visible from the inside out but not the outside in, Blue Fysh and our expert technicians are ready to tackle any project, no matter the scope."
    },
    {
        videoLink:"https://blue-fysh-cdn-c2f316e15a8b.herokuapp.com/uploads/caseStudy/largeScale/vid-2.mp4",
        heading:"Metro Toronto Convention Centre",
        thumbnail:"/images/creatives/thumbnail-5.png",
        content:"Big spaces need big screens, and that’s exactly what we delivered at the Metro Toronto Convention Centre, Canada’s largest convention venue. Get a behind-the-scenes look at how we installed a new modular screen outside one of their biggest halls."
    },
    {
        videoLink:"https://blue-fysh-cdn-c2f316e15a8b.herokuapp.com/uploads/caseStudy/largeScale/vid-1.mp4",
        heading:"Convention center Upgrade",
        thumbnail:"/images/creatives/thumbnail-6.png",
        content:"We don’t just support large spaces, we transform outdated ones into modern, cutting-edge environments. By replacing legacy tech with modular screens that adapt to any size or shape, we bring new life to old spaces. Check out a multi-location install where we upgraded an array of screens at Canada’s largest convention center."
    },
]

function MediaList({
  updateVideoLink
}) {
    
  return (
    <div className='MediaList-container standard-padding-space'>
        <div className='container'>
            <div className='row gy-5'>
                {
                    MediaListAr.map((item,ind) => (
                        <div className={`col-lg-6 px-3 ${(ind %2 !==0) && "second"} px-lg-5 px-1`}
                        
                        >
                            <div className='case-st-card'>
                                <div className='w-100 text-center' onClick={()=>{updateVideoLink(item.videoLink)}} onTouchStart={()=>{updateVideoLink(item.videoLink)}}>
                                    {/* <VideoContainer videoLink={item.videoLink}/> */}
                                    {/* <video autoPlay={false} loop muted controls playsInline controlslist="nodownload" 
                                    style={{width:"100%",borderRadius:"20px"}}>
                                        <source src="" type="video/mp4" />
                                    </video> */}

                                    <img src={item.thumbnail} alt='video thumbnail' style={{width:"100%",borderRadius:"20px",objectFit:"cover",height:"300px",objectPosition:"bottom left"}} />
                                </div>
                                <div className='d-flex flex-column align-items-start  my-4 mb-2'>
                                    <h5 className='body-heading mb-0' style={{fontSize:"30px"}}>{item.heading}</h5>
                                    <p className='body-paragraph mb-0' >{item.content}</p>
                                    
                                </div>
                            </div>
                        </div>
                    ))
                    }
            </div>
        </div>
    </div>
  )
}

const VideoContainer = ({
    videoLink=""
})=>{
    const videoRef = useRef(null)
    const handleMouseEnter = () => {
        if (videoRef.current) {
          videoRef.current.play();
        }
      };
    
      const handleMouseLeave = () => {
        if (videoRef.current) {
          videoRef.current.pause();
        }
      };
    
      const handlePlay = () => {
        if (videoRef.current) {
          videoRef.current.play();
        }
      };
    
      return(
        <video
      ref={videoRef}
      autoPlay={false}
      loop
      muted
      controls
      playsInline
      controlsList="nodownload"
      style={{ width: "100%", borderRadius: "20px" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onPlay={handlePlay}
    >
      <source src={videoLink} type="video/mp4" />
    </video>

      )
    
}

export default MediaList